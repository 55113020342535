.upload-container {
  border: 2px dashed #007bff;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  transition: border 0.3s ease-in-out;
  margin: 20px;
  background-color: #f8f9fa;
}

.upload-container:hover {
  border-color: #0056b3;
  background-color: #e9ecef;
}

.drag-active {
  color: #28a745;
  font-weight: bold;
}
