* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Remove default padding */

ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */

body {
  font: 14px "SFPRODISPLAY", sans-serif;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  overflow-anchor: none;
}

/* Remove list styles on ul, ol elements with a class attribute */

ul[class]:not(.bullet-list),
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */

a {
  color: inherit;
}

a[class] {
  text-decoration: none;
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */

article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */

input,
button,
textarea,
select {
  font: inherit;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

table {
  width: 100%;
}

@font-face {
  font-family: "SFPRODISPLAYBOLD";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("SFPRODISPLAYBOLD"), url("../../fonts/SFPRODISPLAYBOLD.woff2") format("woff2"), url("../../fonts/SFPRODISPLAYBOLD.woff") format("woff");
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "SFPRODISPLAYMEDIUM";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("SFPRODISPLAYMEDIUM"), url("../../fonts/SFPRODISPLAYMEDIUM.woff2") format("woff2"), url("../../fonts/SFPRODISPLAYMEDIUM.woff") format("woff");
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "Shrikhand";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Shrikhand-Regular"), url("../../fonts/Shrikhand-Regular.woff2") format("woff2"), url("../../fonts/Shrikhand-Regular.woff") format("woff");
  unicode-range: U+000-5FF;
}

.heading-1 {
  font-size: 34px;
  font-weight: 700;
  line-height: 1;
}

.heading-2 {
  font-size: 38px;
  font-weight: 700;
  line-height: 1;
}

.heading-3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 1;
}

.heading-4 {
  font-size: 18px;
  line-height: 1.392;
  font-weight: 700;
}

.text-xs {
  font-size: 12px;
}

.text-normal {
  font-size: 14px;
}

.text-md {
  font: 16px "SFPRODISPLAYBOLD", sans-serif;
}

.text-lg {
  font-size: 14px;
}

.text-xl {
  font: 21px "SFPRODISPLAYBOLD", sans-serif;
}

.text-xxl {
  font: 18px "SFPRODISPLAYBOLD", sans-serif;
}

.text-white {
  color: #ffffff;
}

.text-white-2 {
  color: #F9FAFA;
}

.text-dark {
  color: #000000;
}

.text-yellow {
  color: #EDBB11;
}

.text-black {
  color: #161824;
}

.text-center {
  text-align: center;
}

.text-underline {
  border-bottom: 2px solid #000000;
}

.global-preload * {
  -webkit-transition: none !important;
  transition: none !important;
}

body.lock {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.page-wrapper {
  overflow: hidden;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.nav__container {
  max-width: 1380px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.bg-green {
  background: #2A5750;
}

.bg-beige {
  background: #FCF4E1;
}

.bg-mint {
  background: #CCE9E2;
}

.animate-image {
  position: relative;
  display: inline-block;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.animate-image__border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.animate-image__img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 45;
}

.header__wrap {
  padding: 16px 0;
  position: relative;
}

.header__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 25px;
}

.header__logo {
  width: 142px;
  height: 32px;
  cursor: pointer;
}

.header__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 26px;
}

.header__link {
  text-decoration: none;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 35;
  opacity: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.overlay.active {
  opacity: 1;
  pointer-events: all;
}

.btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.btn_full {
  width: 100%;
}

.diamond-btn {
  background: url("../../public/images/diamond-bg.svg") no-repeat center center/contain;
  text-align: center;
  color: #161824;
  padding: 15px 40px;
  font-size: 21px;
  position: relative;
  font: 14px "SFPRODISPLAYBOLD", sans-serif;
}

.diamond-btn:hover {
  background: url("../../public/images/diamond-bg-yellow.svg") no-repeat center center/contain;
}

.diamond-btn:hover::before {
  top: 0;
  left: 0;
}

.diamond-btn--sm {
  font-size: 16px;
  padding: 11px 26px;
}

.diamond-btn--yellow {
  background: url("../../public/images/diamond-bg-yellow.svg") no-repeat center center/contain;
}

.diamond-btn--yellow:hover {
  background: url("../../public/images/diamond-bg.svg") no-repeat center center/contain;
}

.diamond-btn::before {
  background: url("../../public/images/diamond-bg_shadow.svg") no-repeat center center/contain;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 7px;
  left: -5px;
  z-index: -1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.menu__icon__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.menu__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  width: 35px;
  z-index: 5;
  cursor: pointer;
}

.menu__icon span {
  background: #F9FAFA;
  border-radius: 10px;
  height: 4px;
  margin: 3px 0;
  -webkit-transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.menu__icon span:nth-of-type(1) {
  width: 50%;
}

.menu__icon span:nth-of-type(2) {
  width: 100%;
}

.menu__icon span:nth-of-type(3) {
  width: 75%;
}

.menu__icon._active span:nth-of-type(1) {
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transform: rotatez(45deg) translate(8px, 12px);
  -ms-transform: rotate(45deg) translate(8px, 12px);
  transform: rotatez(45deg) translate(8px, 12px);
}

.menu__icon._active span:nth-of-type(2) {
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transform: rotatez(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotatez(-45deg);
}

.menu__icon._active span:nth-of-type(3) {
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  width: 50%;
  -webkit-transform: translate(-15px, -17px) rotatez(45deg);
  -ms-transform: translate(-15px, -17px) rotate(45deg);
  transform: translate(-15px, -17px) rotatez(45deg);
}

.menu-notification-dot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.icon {
  display: inline-block;
  fill: none;
  color: inherit;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.icon_cover {
  max-width: 100%;
  width: auto;
  height: 100%;
}

.fit-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.fit-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.fit-initial {
  -o-object-fit: initial;
  object-fit: initial;
}

#hero__lottie {
  position: absolute;
  top: 46.5%;
  left: 28.5%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 120rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 90;
  display: none;
}

.hero {
  position: relative;
  overflow: hidden;
}

.hero__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  gap: 40px;
  padding: 140px 0 100px;
}

.hero__body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  text-align: center;
}

.hero__title {
  color: #F9FAFA;
  font-family: "Shrikhand", sans-serif;
  font-weight: 400;
  line-height: 120%;
  text-align: left;
}

.hero__btn {
  margin-top: 40px;
}

.hero__image {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  text-align: center;
  display: none;
}

.hero__image--mob {
  max-width: 310px;
  max-height: 310px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.hero__img {
  display: inline-block;
}

.following {
  padding: 56px 0 57px;
}

.following__title {
  padding-bottom: 15px;
}

.following__body {
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 35px;
  max-width: 1100px;
}

.following__img {
  max-width: 65%;
}

.creators {
  padding: 20px 0 60px;
}

.creators__body {
  margin-top: 35px;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  row-gap: 80px;
}

.creators__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  gap: 24px;
}

.creators-card__body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}

.creators-card__title {
  padding-bottom: 15px;
}

.creators-card__text {
  font-weight: 500;
  color: #727272;
}

.creators-card__image {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}

.creators-card__img {
  -o-object-fit: contain;
  object-fit: contain;
}

.creators-card__text + .creators-card__text {
  padding-top: 10px;
}

.cta {
  padding: 80px 0;
  position: relative;
  z-index: 20;
}

.cta__inner {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  gap: 30px;
}

.cta__inner_active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cta__title {
  color: #F9FAFA;
  font-family: "Shrikhand", sans-serif;
  font-weight: 400;
  padding-bottom: 24px;
}

.cta__text {
  color: #F9FAFA;
  padding-bottom: 24px;
}

.cta__nikname {
  display: block;
  color: rgba(249, 250, 250, 0.8);
}

.cta__btn {
  margin-top: 38px;
}

.cta__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  width: 85%;
}

.cta__animate {
  position: absolute;
  bottom: 14%;
  left: 5%;
  width: 50%;
  height: 35%;
}

.cta__animate-img {
  width: 56%;
  height: 59%;
}

.cta__img {
  height: auto;
  margin-left: auto;
  -o-object-fit: contain;
  object-fit: contain;
  width: 80%;
}

.info-steps {
  min-height: 100vh;
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
}

.info-steps__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.info-steps__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 5vw;
}

.info-steps__frame {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 38vh;
  max-width: 155px;
  height: 78vh;
  max-height: 310px;
  border-radius: 20px;
  padding: 10px 10px 6px;
}

.info-steps__screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 101%;
  z-index: 10;
  pointer-events: none;
}

.info-steps__bg {
  position: absolute;
  top: 59px;
  right: -90px;
  pointer-events: none;
  display: none;
  -webkit-animation: rotate 8s linear infinite;
  animation: rotate 8s linear infinite;
}

.info-steps__images {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.info-steps__img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.info-steps__heading {
  margin: 20px 0 30px;
}

.info-steps__title {
  font-family: "Shrikhand", sans-serif;
  font-weight: 400;
  text-align: center;
}

.info-steps__btn {
  display: block;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.step-action {
  text-align: right;
  padding-top: 25px;
}

.step-action__item {
  min-height: 100vh;
  background: #CCE9E2;
  -webkit-box-shadow: 0px -44px 20px 0px rgb(204, 233, 226);
  box-shadow: 0px -44px 20px 0px rgb(204, 233, 226);
}

.step-action__title {
  font-size: 80px;
  line-height: 130%;
  color: #161824;
}

.step-action__text {
  color: #727272;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@supports (-webkit-appearance:none) {
  .diamond-btn {
    background-image: url("../../public/images/diamond-bg.svg");
  }

  .diamond-btn:hover {
    background-image: url("../../public/images/diamond-bg-yellow.svg");
  }

  .diamond-btn--yellow {
    background-image: url("../../public/images/diamond-bg-yellow.svg");
  }

  .diamond-btn--yellow:hover {
    background-image: url("../../public/images/diamond-bg.svg");
  }

  .diamond-btn::before {
    background-image: url("../../public/images/diamond-bg_shadow.svg");
  }
}

@media screen and (min-width: 360px) {
  .following__body {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .following__img {
    max-width: 100%;
  }

  .info-steps__frame {
    max-width: 190px;
    max-height: 385px;
  }

@supports (-webkit-appearance:none) {

}
}

@media screen and (min-width: 560px) {
  .heading-1 {
    font-size: 52px;
  }

  .heading-2 {
    font-size: 48px;
  }

  .heading-3 {
    font-size: 32px;
  }

  .heading-4 {
    font-size: 24px;
  }

  .text-lg {
    font-size: 18px;
  }

  .text-xxl {
    font-size: 24px;
  }

  .following__title {
    padding-bottom: 24px;
  }

  .following__body {
    margin-top: 56px;
  }

  .creators-card__title {
    padding-bottom: 24px;
  }

  .creators-card__text + .creators-card__text {
    padding-top: 20px;
  }

  .cta__body {
    max-width: 415px;
  }

  .cta__animate {
    width: 260px;
    height: 260px;
  }

  .cta__animate-img {
    width: 150px;
    height: 150px;
  }

  .info-steps__frame {
    margin-right: 0;
  }

@supports (-webkit-appearance:none) {

}
}

@media screen and (min-width: 768px) {
  .menu__icon__container {
    display: none;
  }

  #hero__lottie {
    display: block;
  }

  .hero__inner {
    padding: 159px 0 154px;
  }

  .hero__body {
    text-align: left;
  }

  .hero__image {
    display: block;
    width: 440px;
    height: 440px;
    margin: 0 auto;
  }

  .hero__image--mob {
    display: none;
  }

  .creators {
    padding: 72px 0 150px;
  }

  .creators__body {
    margin-top: 72px;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }

  .cta__inner {
    text-align: left;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .cta__image {
    width: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .cta__animate {
    bottom: 8%;
    left: -40%;
    width: 300px;
    height: 300px;
  }

  .cta__animate-img {
    width: 175px;
    height: 175px;
  }

  .cta__img {
    width: auto;
  }

  .info-steps__frame {
    max-width: 290px;
    max-height: 600px;
  }

  .step-action__title {
    font-size: 150px;
  }

@supports (-webkit-appearance:none) {

}
}

@media screen and (min-width: 992px) {
  .heading-1 {
    font-size: 72px;
  }

  .heading-2 {
    font-size: 64px;
  }

  .cta {
    padding: 122px 0;
  }

  .cta__animate {
    bottom: 4%;
    width: 380px;
    height: 380px;
  }

  .cta__animate-img {
    width: 220px;
    height: 220px;
  }

@supports (-webkit-appearance:none) {

}
}

@media screen and (min-width: 1024px) {
  #hero__lottie {
    top: 46.5%;
    left: 35.5%;
  }

  .creators__body {
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
  }

  .creators__item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    text-align: left;
  }

  .info-steps {
    padding: 50px 0;
  }

  .info-steps__inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 5%;
  }

  .info-steps__body {
    margin: 0;
  }

  .info-steps__frame {
    padding: 2% 2% 1.6%;
    width: 38vh;
    max-width: 345px;
    height: 78vh;
    max-height: 702px;
  }

  .info-steps__bg {
    display: block;
  }

  .info-steps__heading {
    margin: 0;
  }

  .info-steps__title {
    padding-top: 15vh;
    text-align: left;
    max-width: 420px;
    font-size: 9vmin;
  }

  .info-steps__btn {
    margin-top: 40px;
    margin-left: 0;
  }

  .step-action {
    margin-top: 120px;
    padding-top: 0;
  }

  .step-action__text {
    max-width: 314px;
  }

@supports (-webkit-appearance:none) {

}
}

@media screen and (min-width: 1248px) {
  .info-steps__inner {
    gap: 9%;
  }

@supports (-webkit-appearance:none) {

}
}

@media screen and (max-width: 767px) {
  .header__body {
    position: absolute;
    top: 101%;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 40px;
    z-index: 40;
    width: 40%;
    min-height: calc(100vh - 65px);
    padding: 35px 15px 35px 30px;
    background: #2A5750;
    -webkit-transform: translate3d(105%, 0, 0);
    transform: translate3d(105%, 0, 0);
    pointer-events: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .header__body._active {
    right: 0;
    pointer-events: all;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

@supports (-webkit-appearance:none) {

}
}

@media screen and (max-width: 559px) {
  .header__body {
    width: 70%;
  }

@supports (-webkit-appearance:none) {

}
}

.animateCentered {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: all 1.6s ease;
}

.move {
  top: 10%;
}

.fadeIn {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s ease-in;
}

.fadeIn.active {
    opacity: 1;
    transform: none;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Shrikhand", sans-serif;
}

.container {
    display: flex;
}

.splash {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.splash img {
    max-width: 400px;
    max-height: 400px;
}

.hire-container {
  display: flex;
}

.hire-splash-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 65px);
  gap: 10%;
  flex: 2 1;
}

.form-container {
    flex: 1;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

input, select {
    padding: 10px;
    font-size: 16px;
    font-family: 'Sf pro display', sans-serif;
    border: 1px solid #AAA;
    border-radius: 5px;
    margin: 10px 0;
}

button {
    color: white;
}

.content-container {
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    min-height: 100vh;
    min-height: 100dvh;
    min-height: -webkit-fill-available;
}

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px;
    padding: 25px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    justify-content: center;
}

.header-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 20px;
    align-items: center;
    max-width: 90%;
}

.profile-section {
    display: flex;
    align-items: flex-start;
    min-width: 300px;
    position: relative;
}

.profilePic-container {
    position: relative;
    cursor: pointer;
    width: 200px;
    height: 200px;
    margin-right: 20px;
    flex-shrink: 0;
}

.profilePic {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    display: block;
}

.edit-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 50%; /* To keep the circular shape of the profilePic */
    font-size: 20px; /* Adjust font size as needed */
    font-family: 'Sf pro display', sans-serif;
    pointer-events: none; /* So that the overlay doesn't interfere with click events */
}

.edit-overlay i {
    color: white;
    font-size: 20px;    /* Adjust this size as per your need */
}

.profilePic-container:hover .edit-overlay {
    opacity: 1;
}

.social-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.social-button-detail-container {
    display: flex;
    gap: 10px;
}

.social-button {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.social-button:hover {
    filter: brightness(90%);
}

.social-button.active {
    background-color: #2a5750;
}

.social-button.pending {
    background-color: #edbb11;
}

.social-button.inactive {
    background-color: gray;
}

.social-detail {
    display: flex;
    margin-left: 10px;
    height: 50px;
    align-items: center;
}

.edit-profile-button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.name-container {
    font-family: Shrikhand, sans-serif;
    margin-bottom: 10px;
}

.name {
    gap: 10px;
    font-size: 32px;
    line-height: 1.5;
}

.display-name {
    font-size: 24px;
}

.market {
    font-family: 'Sf pro display', sans-serif;
    font-size: 16px;
    margin: 6px;
}

.bio {
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.edit-fields {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
}

#businessAddress > div {
  width: 100%;
}

.bordered-edit-fields {
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: #DDDDDD;
  padding: 15px 20px;
}

.large-edit-fields {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
}

.edit-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.select-container {
  width: 250px;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
}

.tag, .bundle {
  padding: 5px 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.tag.selected {
  background-color: #edbb11;
  color: white;
  border: 1px solid transparent;
  border-radius: 5px;
}

.bundle.selected {
  background-color: #2a5750;
  color: white;
  border: 1px solid transparent;
  border-radius: 5px;
}

label {
  font-weight: bold;
  margin-right: 10px;
  flex-shrink: 0;
  width: 100px;
  text-align: right;
}

.required:after {
  content:" *";
  color: red;
}

.input-field, .textarea-field {
  flex-grow: 1;
  padding: 10px;
  border: none;
  background-color: #f5f5f5;
  border-radius: 5px;
  font-size: 16px;
}

.textarea-field {
  resize: vertical;
  width: 100%;
  height: 200px;
}

.hover-container {
  position: relative;
  display: inline-block;
  text-decoration: underline;
}

.hover-text {
    font-size: 16px;
    width: 250px;
    visibility: hidden;
    position: absolute;
    top: 100%;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 3px;
    z-index: 1;
}

.hover-container:hover .hover-text {
  visibility: visible;
}

.big-data-and-badges-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.badges-container {
    display: inline-block;
    gap: 10px;
    max-width: 300px;
}

.badge-container {
    display: inline-flex;
    align-items: center;
    border-radius: 50px;
    margin: 0px 10px 10px 0px;
    background-color: #bbb;
}

.badge-icon-container {
    display: flex;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
}

.badge-icon {
    color: white;
    margin: 5px;
    font-size: 18px;
}

.badge-text {
    color: white;
    font-family: 'Sf pro display', sans-serif;
    font-size: 14px;
    padding: 0px 10px 0px 4px;
}

.big-data-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.big-data-number {
    font-size: 32px;
}

.big-data-label {
    font-size: 18px;
}

.data-item {
    display: flex;
    flex-direction: column;
    padding: 10px;  /* Add padding around each data item */
}

.data-number {
    font-size: 24px;
    font-weight: bold;
}

.data-label {
    font-size: 12px;
}

.chat-list {
  width: 70vw;
}

.chat-container {
  width: 75vw;
  margin: auto;
  padding: 20px;
  box-shadow: 0px 4px 6px #ccc;
  border-radius: 8px;
}

.chat-window {
  min-height: 50vh;
  max-height: 70vh;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
}

.chat-message-input-container {
  display: flex;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  .header-section {
    flex-direction: column;
    gap: 10px;
  }

  .big-data-item {
    flex-direction: row;
  }

  .social-container {
    flex-direction: row;
  }

  .profile-section {
    margin-bottom: 20px;
  }

  .list-controls {
    flex-direction: column;
    align-items: center;
  }

  .hire-container {
    flex-direction: column-reverse;
  }

  .hire-splash-container {
    margin: 80px;
  }
}

@media (max-width: 750px) {
  .profile-section {
    flex-direction: column;
    justify-items: center;
    align-items: center;
    text-align: center;
    gap: 0px;
  }

  .profilePic-container {
    margin-right: 0px;
    width: 150px;
    height: 150px;
  }

  .splash {
    display: none;
  }
}

@media (max-width: 650px) {
  .social-container {
    flex-direction: column;
  }

  .chat-list {
    width: 100%;
  }

  .chat-container {
    width: 100vw;
    box-shadow: none;
    border-radius: 0px;
  }
}

.content-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.bio-section {
    width: 100%;
    max-width: 90%; /* Matched to header-section max-width */
    margin: 20px; /* Matched to header-section margin */
    display: flex;
    justify-content: center; /* Center the bio */
}

.media-grid {
    display: flex;
    width: 100%;
    max-width: 90%; /* Matched to header-section max-width */
    margin: 20px auto; /* Matched to header-section margin */
}

.media-grid_column {
    padding-left: 16px; /* spacing for Masonry columns */
    background-clip: padding-box;
}

.media-grid video {
    max-width: 100%;  /* Ensures the video doesn't grow larger than its container */
    max-height: 500px; /* Adjust this value as per your design requirements */
    object-fit: cover; /* Makes sure the video covers the dimensions specified without stretching */
}

.media-container {
    margin-bottom: 16px;
    padding: 4px;
    border-Radius: 10px;
}

.media-content {
    position: relative;
}

.media-content img, .media-grid video, .media-grid iframe {
    display: block;
    width: 100%;
    border-radius: 10px;
    height: auto;
}

.media-caption {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 14px;
    pointer-events: none;
}

.media-container:hover .media-caption {
    opacity: 1;
}

.media-data {
    display: flex;
    justify-content: space-between;
    padding: 10px 8px 8px 8px;
}

.list-controls {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 40px;
}

.list-container {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #ccc;
  margin: 30px;
  padding: 10px;
}

.campaign-container {
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  gap: 30px;
  justify-content: flex-start;
}

.campaign-card {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.hoverable:hover {
  cursor: pointer;
  transition: all 0.3s;
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}

.card-header {
  position: relative;
  height: 180px;
  overflow: hidden;
}

.card-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-header h3 {
  position: absolute;
  bottom: 0;
  background: #2A5750;
  color: #fff;
  padding: 10px;
  width: 100%;
  text-align: center;
}

.card-body,
.card-footer {
  padding: 10px;
}

.card-footer {
  text-align: center;
}

.good-color {
  color: green;
}

.error-color {
  color: red;
}

.datetime-picker {
  min-width: 120px;
  width: 100%;
  flex-grow: 1;
  padding: 10px;
  border: none;
  background-color: #f5f5f5;
  border-radius: 5px;
  font-size: 16px;
}

.progress-timeline-container {
  display: flex;
  align-items: center;
  padding: 20px;
  white-space: nowrap;
  overflow-x: auto;
}

.progress-timeline-item, .timeline-bar {
  padding: 10px;
  border: 1px solid gray;
  background-color: white;
  border-radius: 5px;
}

.progress-timeline-item.filled {
  background-color: green;
  color: white;
}

.progress-timeline-bar {
  flex: 1;
  height: 2px;
  background-color: gray;
}

.progress-timeline-bar.filled-bar {
  background-color: green;
}

.notification-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  margin-left: 5px;
  vertical-align: middle;
}

.star-rating {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.star {
  font-size: 25px;
}

.star-on {
  color: gold;
}

.star-off {
  color: grey;
}

.stat-card-green-bar {
  display: flex;
  background-color: #4CAF50;
  width: 100%;
  border-radius: 10px;
}

.stat-card-green-bar-text {
  color: white;
  font-size: 14px;
  padding: 10px;
  align-items: center;
}

.stat-card-container {
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.stat-card-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.stat-card-header-title {
  font-size: 32px;
  font-weight: bold;
}

.stat-card-header-subtitle {
  color: grey;
  font-size: 12px;
}

.stat-card-body {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.stat-card-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #e1e1e1;
  padding: 10px;
  border-radius: 5px;
  width: 150px;
}

.stat-card-item-label {
  color: grey;
  font-size: 10px;
}

.stat-card-item-value {
  font-size: 32px;
  font-weight: bold;
}

.rdt {
  width: 100%;
}

.line-through {
  text-decoration: line-through;
}

.payments-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  font-size: 16px;
}

.payments-list-item:last-child {
  border-bottom: none;
}

.payments-list-item-title {
  font-weight: bold;
  margin-right: 10px;
  margin-bottom: 10px;
}

.payments-list-item-detail {
  color: #666;
}

.payments-list-item-status-container {
  width: fit-content;
  align-items: center;
  border-radius: 20px;
  background-color: #888;
  color: #fff;
}

.payments-list-item-status {
  margin: 0px 8px;
}
