.socialMediaMasonryGrid {
  display: flex;
  margin-left: -30px;
  width: auto;
}
.socialMediaMasonryGridColumn {
  padding-left: 30px;
  background-clip: padding-box;
}

.socialMediaCard {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
}

.socialMediaCardHeader {
  color: #333;
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
}

.socialMediaChartContainer {
  width: 100%;
  height: 300px;
}
